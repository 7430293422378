import { Auth } from 'aws-amplify';
import { useFormik } from 'formik';
import { AnimatePresence } from 'framer-motion';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { CognitoUserExt } from '../../../App.types';
import { AuthState, updateAuthStatus } from '../../../slices/auth/auth';
import { Button } from '../../Button/Button';
import { Input } from '../../Input/Input';
import { Loader } from '../../Loader/Loader';
import { P } from '../../Typography/Typography';
import { Wrapper } from '../../Wrapper/Wrapper';
import { FieldMessage, LoginWrapper } from '../Login.styles';
import { VERIFY_MFA_VALIDATION_SCHEMA } from './VerifyMFA.constants';

export const VerifyMFA: React.FC<{ user: CognitoUserExt; firstSetup: boolean; resetTOTP?: () => void }> = ({
	firstSetup,
	user,
}) => {
	const dispatch = useDispatch();
	const [error, setError] = useState<string>();
	const [loading, setLoading] = useState<boolean>();

	const verifyCode = async ({ code }: { code: string }) => {
		setLoading(true);

		if (!code) {
			setError('Verification code is required.');
			setLoading(false);
			return;
		}

		try {
			if (firstSetup) {
				// on firstSetup, pair account with token then store preferred choice
				await Auth.verifyTotpToken(user, code);
				await Auth.setPreferredMFA(user, 'TOTP');
			} else {
				// if not firstSetup, check the token against the stored account
				await Auth.confirmSignIn(user, code, 'SOFTWARE_TOKEN_MFA');
			}

			dispatch(updateAuthStatus(AuthState.SIGNED_IN));
		} catch (e) {
			setError(e.message);
			setLoading(false);
		}
	};

	const { values, errors, handleSubmit, handleChange } = useFormik({
		initialValues: {
			code: '',
		},
		validationSchema: VERIFY_MFA_VALIDATION_SCHEMA,
		onSubmit: verifyCode,
	});

	//TODO: replace 1 input by 6 single inputs
	return (
		<form onSubmit={handleSubmit} noValidate>
			<P mb="sm">Two-step verification</P>
			<P fontSize="14px" lineHeight="18px" mb="md" variant="dark">
				Please enter 6-digit code generated by your authenticator app.
			</P>
			<Input
				mb="sm"
				inputMode="numeric"
				autocomplete="one-time-code"
				name="code"
				label="Verification code"
				value={values.code}
				onChange={handleChange}
			/>
			{(error || errors) && <FieldMessage mb="sm">{error || errors.code}</FieldMessage>}
			{/* TODO: give choice to setup QR code again */}
			<Wrapper display="flex">
				<Button type="submit">Submit</Button>
				<AnimatePresence>
					{loading && (
						<LoginWrapper initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} ml="sm">
							<Loader size={20} />
						</LoginWrapper>
					)}
				</AnimatePresence>
			</Wrapper>
		</form>
	);
};

import styled from 'styled-components';
import {
	background,
	BackgroundProps,
	compose,
	display,
	DisplayProps,
	flexbox,
	FlexboxProps,
	layout,
	LayoutProps,
	position,
	PositionProps,
	space,
	SpaceProps,
} from 'styled-system';

export const Wrapper = styled.div<
	PositionProps & FlexboxProps & DisplayProps & SpaceProps & BackgroundProps & LayoutProps
>`
	${compose(background, position, flexbox, display, space, layout)}
`;

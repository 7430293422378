import { gql, useMutation } from '@apollo/client';
import { useFormik } from 'formik';
import React from 'react';
import { useHistory } from 'react-router-dom';
import {
	UpdateUserMutationInput,
	UpdateUserMutationResponse,
	UPDATE_USER_MUTATION,
} from '../../../graphql/mutations/user/updateUser';
import { UserData } from '../../../graphql/queries/user/user.types';
import { Button } from '../../Button/Button';
import { Input } from '../../Input/Input';
import { P } from '../../Typography/Typography';
import { Wrapper } from '../../Wrapper/Wrapper';
import { FieldMessage } from '../Login.styles';
import { UPDATE_DETAILS_VALIDATION_SCHEMA } from './UpdateDetails.constants';
import { UpdateUserProps } from './UpdateDetails.types';

export const UpdateDetails: React.FC<{ user?: UserData }> = ({ user }) => {
	const history = useHistory();
	const [updateUser] = useMutation<UpdateUserMutationResponse, UpdateUserMutationInput>(UPDATE_USER_MUTATION, {
		update(cache, { data }) {
			try {
				cache.modify({
					id: cache.identify(data?.updateUser.id as any),
					fields: {
						users(existingUsers: UserData[]) {
							const newUser = cache.writeFragment({
								data: data?.updateUser,
								fragment: gql`
									fragment NewUser on User {
										id
										name
										firstName
										phone
									}
								`,
							});

							return [...existingUsers, newUser];
						},
					},
				});
			} catch (e) {
				console.warn(e);
			}
		},
	});
	const { values, errors, touched, handleChange, handleSubmit } = useFormik<UpdateUserProps>({
		initialValues: {
			firstName: user?.firstName || '',
			name: user?.name || '',
			phone: user?.phone || '',
		},
		validationSchema: UPDATE_DETAILS_VALIDATION_SCHEMA,
		onSubmit: async (data) => {
			await updateUser({ variables: { setName: data.name, setFirstName: data.firstName, setPhone: data.phone } });
			history.replace('/');
		},
	});

	return (
		<>
			<P mb="lg" as="div">
				Please update your details.
			</P>

			<form onSubmit={handleSubmit}>
				<Wrapper mb="md">
					<Input
						status={touched.firstName && errors.firstName ? 'error' : undefined}
						name="firstName"
						label="First name"
						value={values.firstName}
						onChange={handleChange}
					/>
				</Wrapper>
				<Wrapper mb="md">
					<Input
						status={touched.name && errors.name ? 'error' : undefined}
						name="name"
						label="Last name"
						value={values.name}
						onChange={handleChange}
					/>
				</Wrapper>
				<Wrapper mb="md">
					<Input
						placeholder="+44 ..."
						status={touched.phone && errors.phone ? 'error' : undefined}
						name="phone"
						label="Phone number (include country code)"
						value={values.phone}
						onChange={handleChange}
					/>
				</Wrapper>
				{Object.values(errors).length > 0 && <FieldMessage my="md">{Object.values(errors)[0]}</FieldMessage>}
				<Button variant="dark" type="submit">
					Save
				</Button>
			</form>
		</>
	);
};

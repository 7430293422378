import { AnimatePresence } from 'framer-motion';
import React from 'react';
import { SignIn } from './SignIn/SignIn';

export const Login = () => {
	return (
		<AnimatePresence>
			<SignIn />
		</AnimatePresence>
	);
};

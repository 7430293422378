import curriedTransparentize from 'polished/lib/color/transparentize';
import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { Login } from '../../components/Login/Login';
import { SEO } from '../../components/SEO/SEO';
import { Wrapper } from '../../components/Wrapper/Wrapper';
import { LoginCredits, LoginLogo } from './LoginPage.styles';

export const LoginPage = () => {
  const { colors } = useContext(ThemeContext);

  return (
    <Wrapper height="100vh" background={colors.loginBg} display="flex">
      <SEO title="Sign In" />
      <Wrapper
        alignItems="center"
        display="flex"
        position={{ default: 'absolute', md: 'relative' }}
        height="100%"
        justifyContent="center"
        zIndex={1}
        px={{ default: 'md', sm: 'xxl', md: 'md' }}
        width={{ default: '100vw', md: '50vw' }}
      >
        <Wrapper display="flex" flexDirection="column" width={{ default: '100%', md: '50%' }}>
          <Login />
        </Wrapper>
      </Wrapper>
      <Wrapper width={{ default: '100vw', md: '50vw' }} backgroundSize="cover" backgroundImage="url('./tess_login_bg.png')">
        <Wrapper
          alignItems="center"
          position="relative"
          background={{
            default: curriedTransparentize(0.9, colors.loginBg),
            md: curriedTransparentize(0.95, colors.loginBg),
          }}
          display="flex"
          height="100%"
          justifyContent="center"
        >
          <LoginLogo display={{ default: 'none', md: 'initial' }} src="./tessa.png" />
          <LoginCredits>
            © ESA,
            <a href="https://creativecommons.org/licenses/by-sa/3.0/igo/" target="_blank" rel="noreferrer">
              CC BY-SA 3.0 IGO
            </a>
          </LoginCredits>
        </Wrapper>
      </Wrapper>
    </Wrapper>
  );
};

import { Auth } from 'aws-amplify';
import { useFormik } from 'formik';
import React, { useCallback, useState } from 'react';
import { Button } from '../../Button/Button';
import { Input } from '../../Input/Input';
import { Loader } from '../../Loader/Loader';
import { P } from '../../Typography/Typography';
import { Wrapper } from '../../Wrapper/Wrapper';
import { LOGIN_SORTED_ATTRIBUTES } from '../Login.constants';
import { FieldMessage } from '../Login.styles';
import { VERIFY_ACCOUNT_VALIDATION_SCHEMA } from './VerifyAccount.constants';
import { VerifyAccountForm, VerifyAccountProps } from './VerifyAccount.types';

export const VerifyAccount: React.FC<VerifyAccountProps> = ({ onVerified, user }) => {
	const [error, setError] = useState<string>();
	const [loading, setLoading] = useState<boolean>(false);
	// sort attributes in pre-defined order to make it more user friendly
	const attributeFields =
		user.challengeParam?.requiredAttributes?.sort((a, b) => {
			return LOGIN_SORTED_ATTRIBUTES.indexOf(a) - LOGIN_SORTED_ATTRIBUTES.indexOf(b);
		}) || [];

	const verifyAccount = async ({ password, attributes }: VerifyAccountForm) => {
		setLoading(true);
		try {
			// turns array of attributes onto an object with labels as keys
			const attrs = Object.fromEntries(attributes.map((item) => [item.label, item.value]));
			const updatedUser = await Auth.completeNewPassword(user, password, attrs);
			// pass new object up to parent to trigger next step
			onVerified(updatedUser);
		} catch (e) {
			// display error from Cognito
			setError(e.message);
			setLoading(false);
		}
	};

	const { errors, values, handleChange, handleSubmit, touched } = useFormik<VerifyAccountForm>({
		initialValues: {
			password: '',
			passwordConfirm: '',
			attributes: attributeFields.map((attribute) => {
				return { label: attribute, value: '' };
			}),
		},
		validationSchema: VERIFY_ACCOUNT_VALIDATION_SCHEMA,
		validateOnChange: false,
		validateOnBlur: true,
		onSubmit: verifyAccount,
	});

	// const onAttributeChange = (attributeName: keyof UserAttributes, attributeValue: string) => {
	// 	const attributes = values.attributes.map((attribute) => {
	// 		return {
	// 			...attribute,
	// 			value: attribute.label === attributeName ? attributeValue : attribute.value,
	// 		};
	// 	});

	// 	setValues({ ...values, attributes });
	// };

	const formatError = useCallback(() => {
		// return early of no errors - just in case
		if (!Object.keys(errors).length) {
			return null;
		}

		//if first error isn't attributes related, display its value
		if (Object.keys(errors)[0] !== 'attributes') {
			return Object.values(errors)[0];
		}

		//if attributes are missing, display the right error
		if (errors.attributes) {
			return 'Your details are required.';
		}
	}, [errors]);

	//TODO: improve markup
	return (
		<>
			<P mb="sm">Please change your password</P>
			<P fontSize="14px" lineHeight="18px" mt="md" mb="lg">
				Your password needs to contain at least: <b>1 lowercase, 1 uppercase, 8 characters.</b>
			</P>

			<form onSubmit={handleSubmit}>
				<Input
					mb="md"
					status={touched.password && errors.password ? 'error' : undefined}
					name="password"
					label="New password"
					value={values.password}
					onChange={handleChange}
					type="password"
				/>
				<Input
					mb="md"
					name="passwordConfirm"
					label="Confirm your new password"
					type="password"
					status={touched.passwordConfirm && errors.passwordConfirm ? 'error' : undefined}
					value={values.passwordConfirm}
					onChange={handleChange}
				/>

				{/* {attributeFields.map((attribute, i) => (
					<Input
						key={i}
						mb="xs"
						//@ts-ignore
						label={LOGIN_ATTRIBUTES[attribute]}
						name={attribute}
						placeholder={attribute === 'phone_number' ? 'Include the country code (+44...)' : undefined}
						value={values.attributes[i]?.value}
						onChange={(e) => onAttributeChange(attribute, e.currentTarget.value)}
					/>
				))} */}

				{errors && <FieldMessage my="sm">{formatError()}</FieldMessage>}
				{error && <FieldMessage my="sm">{error}</FieldMessage>}
				<Wrapper display="flex" alignItems="center" mt="sm">
					<Button type="submit">Save</Button>
					{loading && <Loader ml="xs" size={20} />}
				</Wrapper>
			</form>
		</>
	);
};

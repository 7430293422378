import styled from 'styled-components';
import { display, DisplayProps } from 'styled-system';

export const LoginLogo = styled.img<DisplayProps>`
	width: 450px;
	${display}
`;

export const LoginCredits = styled.span`
	color: ${({ theme }) => theme.colors.brandFontDark};
	font-size: 10px;
	position: absolute;
	opacity: 0.7;
	bottom: 12px;
	right: 12px;
	a {
		margin-left: 4px;
	}
`;

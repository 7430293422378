import styled, { css } from 'styled-components';
import {
  color,
  ColorProps,
  compose,
  fontSize,
  FontSizeProps,
  fontWeight,
  FontWeightProps,
  lineHeight,
  LineHeightProps,
  opacity,
  OpacityProps,
  space,
  SpaceProps,
  variant,
} from 'styled-system';

const commonProps = `
color: white;
`;

export const H1 = styled.h1<FontSizeProps & FontWeightProps & SpaceProps>`
  /* font-family: 'IBM Plex Sans', sans-serif; */
  font-size: 48px;
  font-weight: 300;
  ${commonProps}
  ${compose(space, fontSize, fontWeight)}
`;

export const H2 = styled.h2`
  /* font-family: 'IBM Plex Sans', sans-serif; */
  font-size: 42px;
  font-weight: 300;
  ${commonProps}
`;

export const Strong = styled.strong`
  color: ${({ theme }) => theme.colors.brandFontDark};
  /* font-family: 'IBM Plex Sans', sans-serif; */
  font-size: 24px;
  font-weight: 600;
  ${commonProps}
`;

export const P = styled.p<{ variant?: 'dark' } & FontSizeProps & SpaceProps & LineHeightProps>`
  ${({ theme }) => css`
    /* font-family: 'IBM Plex Sans', sans-serif; */
    font-size: 20px;
    font-weight: 300;
    ${commonProps}

    ${variant({
      variants: {
        dark: {
          color: theme.colors.brandFontDark,
        },
      },
    })}
  `}
  ${compose(fontSize, lineHeight, space)}
`;

export const Legend = styled.strong<FontSizeProps & OpacityProps>`
  ${({ theme }) => css`
    color: white
    /* font-family: 'IBM Plex Sans', sans-serif; */
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.35px;
    opacity: 0.6;
    text-transform: uppercase;
    ${compose(opacity, fontSize)}
  `}
`;

export const Mono = styled.p<ColorProps & FontWeightProps & FontSizeProps & SpaceProps>`
  color: white;
  /* font-family: 'IBM Plex Mono', monospace; */
  font-size: 16px;
  ${compose(color, fontSize, fontWeight, space)}
`;

import * as Yup from 'yup';

export const VERIFY_ACCOUNT_VALIDATION_SCHEMA = Yup.object({
	password: Yup.string()
		.matches(/^(?=.*?[A-Z])(?=.*?[a-z]).{8,}$/, 'Your password does not match the requirements.')
		.required('Password is required.'),
	passwordConfirm: Yup.string()
		.oneOf([Yup.ref('password'), null], 'Passwords must match.')
		.required('You must confirm your password.'),
	attributes: Yup.array().of(
		Yup.object().shape({
			label: Yup.string(),
			value: Yup.string().required('Your details are required.'),
		}),
	),
});

import React, { ChangeEvent } from 'react';
import { SpaceProps } from 'styled-system';
import { InputContainer, InputLabel, StyledInput } from './Input.styles';

export const Input: React.FC<
	{
		autocomplete?: string;
		inputMode?: 'none' | 'text' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search';
		label: string;
		placeholder?: string;
		type?: string;
		name: string;
		onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
		status?: 'error';
		value: any;
	} & SpaceProps
> = ({ onChange, autocomplete, inputMode, placeholder, label, value, name, status, type = 'text', ...rest }) => {
	return (
		<InputContainer {...rest}>
			{label && <InputLabel>{label}</InputLabel>}
			<StyledInput
				onChange={onChange}
				variant={status}
				name={name}
				value={value}
				placeholder={placeholder}
				type={type}
				inputMode={inputMode}
				autoComplete={autocomplete}
			/>
		</InputContainer>
	);
};

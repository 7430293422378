import lighten from 'polished/lib/color/lighten';
import curriedTransparentize from 'polished/lib/color/transparentize';

const baseColors = {
  black: '#141414',
  blackLight: '#1A1A1A',
  blackLighter: '#202020',

  // purple brand
  brandPrimary: '#7A6EEA',
  brandSecondary: '#27243A',

  brandFontDark: '#A4A6BA',

  grey: '#4D4D4D',
  greyLight: '#A6A6A6',

  metricLight: '#514DAA',
  metricDark: '#2D2B46',

  //buttons
  buttonLight: '#7772E2',
  buttonDark: '#2D2B46', //'#446b77'

  loginBg: '#000000',
  inputPrimary: 'rgba(255,255,255,.12)',

  danger: '#FF454C',
  success: '#4EFF8B',

  riskLow: '#2F6542',
  riskMed: '#655A2D',
  riskHigh: '#652D2F',
};

export const colors = {
  ...baseColors,
  charts: {
    positive: curriedTransparentize(0.4, baseColors.success),
    negative: curriedTransparentize(0.4, baseColors.danger),
    grid: curriedTransparentize(0.7, baseColors.grey),
  },
  tables: {
    //TODO: Reduce number of colours being used here/use curriedTransparentize more
    sectionTitle: '#1F1F1F',
    header: '#faf0f0',
    cell: '#191919',
    confirm: '#36b957',
    cancel: '#6e6e6e',
    detailTitle: '#A6A6A6',
    borders: '#383838',
    headerBorders: '#808080',
    rowHighlight: '#39365D',
    riskLow: baseColors.riskLow,
    riskMed: baseColors.riskMed,
    riskHigh: baseColors.riskHigh,
    riskUnknown: '#656565',
    filterBase: baseColors.brandPrimary,
    filterIconBg: curriedTransparentize(0.1, baseColors.brandPrimary),
    filterAddBg: curriedTransparentize(0.7, baseColors.brandPrimary),
    selecetedHeader: '#404040',
    selecetedCell: '#292929',
    filterOptionHover: '#2A273B',
    filterLabelColor: '#B7B2F3',
  },
  map: {
    riskLow: lighten(0.2, baseColors.riskLow),
    riskMed: lighten(0.2, baseColors.riskMed),
    riskHigh: lighten(0.2, baseColors.riskHigh),
  },
};

import { Auth } from 'aws-amplify';
import { AnimatePresence, motion } from 'framer-motion';
import QRCode from 'qrcode.react';
import React, { useEffect, useState } from 'react';
import { APP_ISSUER, APP_NAME } from '../../../App.constants';
import { CognitoUserExt } from '../../../App.types';
import { Button } from '../../Button/Button';
import { P } from '../../Typography/Typography';
import { Wrapper } from '../../Wrapper/Wrapper';
import { LOGIN_ANIMATION_VARIANTS } from '../Login.constants';
import { FieldMessage } from '../Login.styles';
import { VerifyMFA } from '../VerifyMFA/VerifyMFA';

export const SetupMFA: React.FC<{ user: CognitoUserExt; username?: string }> = ({ user, username }) => {
	const [code, setCode] = useState<string>();
	const [error, setError] = useState<string>();
	const [setupDone, setSetup] = useState(false);

	useEffect(() => {
		const initQRCode = async () => {
			try {
				const code = await Auth.setupTOTP(user);
				setCode(code);
			} catch (e) {
				setError(e.message);
			}
		};

		initQRCode();
	}, [user]);

	// use previously entered username for OTP app - fallback to API username
	const getQRCode = () => {
		return `otpauth://totp/${APP_NAME}:${username || user.username}?secret=${code}&issuer=${APP_ISSUER}`;
	};

	return (
		<AnimatePresence exitBeforeEnter>
			{!setupDone ? (
				<motion.div key="setupMFA" variants={LOGIN_ANIMATION_VARIANTS} initial="initial" animate="animate" exit="exit">
					<P mb="sm">Set up Multi-factor authentication (MFA)</P>
					<P mb="sm" fontSize="14px" lineHeight="18px">
						Please scan this code to link your account to your authenticator app (i.e. Authy, Google Authenticator)
					</P>
					{code && (
						<>
							<Wrapper my="sm">
								<QRCode value={getQRCode()} size={200} includeMargin />
							</Wrapper>

							<Button onClick={() => setSetup(true)}>Done</Button>
						</>
					)}
					{error && <FieldMessage>{error}</FieldMessage>}
				</motion.div>
			) : (
				<motion.div key="verifyMFA" variants={LOGIN_ANIMATION_VARIANTS} initial="initial" animate="animate" exit="exit">
					<VerifyMFA firstSetup user={user} />
				</motion.div>
			)}
		</AnimatePresence>
	);
};

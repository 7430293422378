import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface NavState {
	title: string;
}
const initialState: NavState = { title: '' };

export const navSlice = createSlice({
	name: 'nav',
	initialState,
	reducers: {
		updateNavTitle(state, action: PayloadAction<string>) {
			state.title = action.payload;
		},
	},
});
export const { updateNavTitle } = navSlice.actions;
export default navSlice.reducer;

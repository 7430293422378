import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum AuthState {
	SIGNED_IN = 'SIGNED_IN',
	SIGNED_OUT = 'SIGNED_OUT',
	PENDING = 'PENDING',
}

// export interface AuthState {
// 	user: UserAttributes | undefined | null;
// }
const initialState: { authStatus: keyof typeof AuthState } = { authStatus: AuthState.PENDING };
export const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		updateAuthStatus(state, action: PayloadAction<keyof typeof AuthState>) {
			state.authStatus = action.payload;
		},
	},
});
export const { updateAuthStatus } = authSlice.actions;
export default authSlice.reducer;

export const xs = 4;
export const sm = 8;
export const md = 16;
export const lg = 32;
export const xl = 64;
export const xxl = 128;

export function formatSpace(space: number) {
	return `${space}px`;
}

export const space = {
	xs: formatSpace(xs),
	sm: formatSpace(sm),
	md: formatSpace(md),
	lg: formatSpace(lg),
	xl: formatSpace(xl),
	xxl: formatSpace(xxl),
};

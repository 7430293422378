import Amplify from 'aws-amplify';
import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { App } from './App';
import awsConfig from './config/awsConfig';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { store } from './slices/store';
import { GlobalStyle } from './styles/globalStyle';
import { theme } from './styles/theme';

// populate Cognito params to AmplifyJS
Amplify.configure(awsConfig);
ReactGA.initialize(process.env.REACT_APP_GA_ID);
ReactDOM.render(
	<React.StrictMode>
		<ThemeProvider theme={theme}>
			<GlobalStyle />
			<Provider store={store}>
				<App />
			</Provider>
		</ThemeProvider>
	</React.StrictMode>,
	document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import styled, { css } from 'styled-components';
import { compose, layout, LayoutProps, space, SpaceProps, variant } from 'styled-system';

export const StyledButton = styled.button<
  { variant: 'large' | 'light' | 'dark' | 'darkToggle' | 'darkDisabled' | 'lightToggle' } & LayoutProps & SpaceProps
>`
  ${({ theme }) => css`
    align-items: center;
    background: transparent;
    border: 1px solid;
    border-radius: 11px;
    color: white;
    cursor: pointer;
    display: flex;
    font-size: 22px;
    font-weight: 600;
    padding: 25px 16px 25px 16px;
    pointer-events: all;
    text-transform: uppercase;
    text-decoration: none;
    transition: background 0.2s ease-out;
    width: 100% !important;
    max-width: 300px;
    justify-content: center;

    ${variant({
      variants: {
        light: {
          backgroundColor: theme.colors.buttonLight,
          borderColor: theme.colors.buttonLight,
          [theme.mediaQueries.md]: {
            backgroundColor: 'transparent',
          },
          '&:hover': {
            backgroundColor: theme.colors.buttonLight,
          },
        },
        dark: {
          backgroundColor: theme.colors.buttonDark,
          borderColor: theme.colors.buttonDark,
          marginY: '2px',
          [theme.mediaQueries.md]: {
            backgroundColor: theme.colors.buttonDark,
          },
          '&:hover': {
            backgroundColor: '#5795a9',
          },
        },
        darkToggle: {
          fontSize: 12,
          borderRadius: 0,
          backgroundColor: theme.colors.buttonDark,
          borderColor: theme.colors.buttonDark,
          '&:hover': {
            backgroundColor: theme.colors.buttonDark,
          },
        },
        darkDisabled: {
          borderColor: theme.colors.buttonDark,
          marginY: '2px',
          backgroundColor: '#474747',
          opacity: '.5',
          cursor: 'not-allowed',
          pointerEvents: 'none',
        },
        lightToggle: {
          fontSize: 12,
          borderRadius: 0,
          backgroundColor: 'transparent',
          borderColor: theme.colors.buttonDark,
          '&:hover': {
            backgroundColor: theme.colors.buttonDark,
          },
        },
      },
    })}
    ${compose(layout, space)}
  `}
`;

export const ButtonImage = styled.img`
  display: inline-block;
  height: 20px;
  margin-right: 18px;
`;

export const ButtonType = styled.span`
  ${({ theme }) => css`
    color: white;
    font-size: 11px;
    font-weight: 300;
    letter-spacing: 0.28px;
    margin-left: 12px;
    text-transform: none;
  `}
`;

export interface Breakpoints {
	sm: string;
	md: string;
	lg: string;
	xl: string;
	xxl: string;
}

export const sm: number = 768;
export const md: number = 1024;
export const lg: number = 1440;
export const xl: number = 1920;
export const xxl: number = 2100;

export const breakpoints: string[] & Breakpoints = Object.assign([`${sm}px`, `${md}px`, `${lg}px`, `${xl}px`], {
	default: '0px',
	sm: `${sm}px`,
	md: `${md}px`,
	lg: `${lg}px`,
	xl: `${xl}px`,
	xxl: `${xxl}px`,
});

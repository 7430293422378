import { UserAttributes } from '../../App.types';

// map Cognito attributes to user friendly labels
export const LOGIN_ATTRIBUTES: UserAttributes = {
	name: 'First name',
	family_name: 'Last name',
	phone_number: 'Phone number',
	sub: 'Username',
	email: 'Email',
	email_verified: 'Email verified',
	updated_at: 'Updated at',
};

export const LOGIN_SORTED_ATTRIBUTES: Array<keyof UserAttributes> = ['name', 'family_name', 'phone_number', 'email'];

export const LOGIN_ANIMATION_VARIANTS = {
	initial: {
		opacity: 0,
		x: 10,
	},
	animate: (i: number) => ({
		opacity: 1,
		x: 0,
		transition: { delay: i },
	}),
	exit: { opacity: 0, x: -10 },
};

const isProd = true; //process.env.NODE_ENV !== 'development';

const awsConfig = {
  Auth: {
    region: 'eu-central-1',
    userPoolId: process.env.REACT_APP_AUTH_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AUTH_CLIENT_ID,
    mandatorySignIn: false,
    cookieStorage: {
      domain: 'localhost',
      path: '/',
      expires: 365,
      secure: isProd,
    },
    oauth: {
      federationTarget: 'COGNITO_USER_POOLS',
      domain: 'bs.auth.eu-central-1.amazoncognito.com',
      scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: 'https://bs.hsat.info/oauth2/idpresponse',
      redirectSignOut: 'https://bs.hsat.info/cognito-logout/',
      responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  },
};

export default awsConfig;

import { useLazyQuery } from '@apollo/client';
import { Auth, Hub } from 'aws-amplify';
import { useFormik } from 'formik';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { ChallengeName, CognitoUserExt } from '../../../App.types';
import { ME_QUERY } from '../../../graphql/queries/user/me';
import { UserData } from '../../../graphql/queries/user/user.types';
import { AuthState, updateAuthStatus } from '../../../slices/auth/auth';
import { RootState } from '../../../slices/store';
import { Button } from '../../Button/Button';
import { Input } from '../../Input/Input';
import { Loader } from '../../Loader/Loader';
import { Wrapper } from '../../Wrapper/Wrapper';
import { LOGIN_ANIMATION_VARIANTS } from '../Login.constants';
import { FieldMessage, LoginWrapper } from '../Login.styles';
import { SetupMFA } from '../SetupMFA/SetupMFA';
import { UpdateDetails } from '../UpdateDetails/UpdateDetails';
import { VerifyAccount } from '../VerifyAccount/VerifyAccount';
import { VerifyMFA } from '../VerifyMFA/VerifyMFA';
import { SIGNIN_VALIDATION_SCHEMA } from './SignIn.constants';
import { SignInForm } from './SignIn.types';
import { removeUselessCookies } from './SignIn.utils';
import { toast } from 'react-toastify';
import styled, { css } from 'styled-components';

const StyledP = styled.p`
padding-bottom: 10px;
line-height: 20px;
`;
const StyledHeadingP = styled.p`
padding-bottom: 15px;
line-height: 38px;
font-size: 30px
`;


export const SignIn: React.FC = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const authStatus = useSelector((state: RootState) => state.auth.authStatus);

  const [challenge, setChallenge] = useState<{ challengeName?: ChallengeName; challengeParam?: any }>();
  const [user, setUser] = useState<CognitoUserExt | undefined>(undefined);
  const [error, setError] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [getUserDetails, { data: userData }] = useLazyQuery<{ me: UserData }>(ME_QUERY, {
    // fetchPolicy: 'network-only',
    // onCompleted: (data: { me: UserData }) => {
    //   if (data.me.firstName && data.me.name && data.me.phone) {
    //     removeUselessCookies();
    //     ReactGA.event({ category: 'User', action: 'Logged in' });
    //     history.replace('/');
    //     // if some/all details are missing, force user to update them
    //   } else {
    //     setChallenge({ challengeName: 'UPDATE_DETAILS', challengeParam: {} });
    //   }
    // },
  });

  const signIn = async ({ username, password }: SignInForm) => {
    // reset error on submit
    setError(undefined);
    setLoading(true);

    try {
      // remove whitespaces from email address as Amazon doesn't like spaces
      const user: CognitoUserExt = await Auth.signIn(username.trim(), password);
      setUser(user);
      setChallenge({ challengeName: user.challengeName, challengeParam: user.challengeParam });
    } catch (e) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  };

  const updateUser = (user: CognitoUserExt) => {
    setUser(user);
    setChallenge({
      challengeName: user.challengeName,
      challengeParam: user.challengeParam,
    });
    setMessage(JSON.stringify(user));
  };

  // update AuthStatus based on localStorage - on mount only
  useEffect(() => {
    if (authStatus !== AuthState.SIGNED_IN) {
      Auth.currentSession()
        // if the token is still valid, get user details from DB?
        .then((userData: any) => {
          // update AuthStatus
          dispatch(updateAuthStatus(AuthState.SIGNED_IN));
          getUserDetails();
          console.log('USER STILL AUTHENTICATED', userData);
          setMessage(JSON.stringify(userData.accessToken.payload));
        })
        // otherwise, force user to go through authentication flow
        .catch(() => {
          Auth.signOut().finally(() => {
            dispatch(updateAuthStatus(AuthState.SIGNED_OUT));
          });
        });
    }
  }, []);

  // handle user session on Auth Status change
  useEffect(() => {
    if (authStatus === AuthState.SIGNED_IN) {
      Auth.currentSession().then((userData: any) => {
        getUserDetails();
        setMessage(JSON.stringify(userData.accessToken.payload));
      });
    }
  }, [authStatus]);

  const { values, handleChange, handleSubmit, errors, touched } = useFormik<SignInForm>({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: SIGNIN_VALIDATION_SCHEMA,
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit: signIn,
  });

  // hide layout while loading authStatus
  if (authStatus === AuthState.PENDING) {
    return null;
  }

  return (
    <>
      {message.length ? message : ''}

      <AnimatePresence exitBeforeEnter>
        {!user && authStatus !== AuthState.SIGNED_IN && (
          <motion.div
            key="signIn"
            variants={LOGIN_ANIMATION_VARIANTS}
            initial="initial"
            animate="animate"
            exit="exit"
            custom={0.5}
          >
            <Wrapper alignItems="center" display="flex" justifyContent="center" width="100%">
              <Wrapper display="flex" flexDirection="column" width="500px">
                <StyledHeadingP>Tessa: Get Paid to Take Pictures of Crops</StyledHeadingP>
                <StyledP>We are collecting information about crops from around the world. We use this information to understand the world’s food supplies.</StyledP>
                <StyledP>We pay people who live near crops to collect this information – taking pictures and conducting surveys.</StyledP>
                <StyledP>We have had over 1,000 people collect data from Brazil to Bangladesh.</StyledP>
                <StyledP><strong>We are now expanding. We want 10,000 people to collect around the world.</strong></StyledP>
                <StyledP >Do you have an Android mobile phone? Do you want to get paid to get pictures of fields and crops?</StyledP>
                <StyledP><strong>Do you want to join our team? Sign up below</strong></StyledP>                

                <form action="https://app.tessa.hsat.info" className="button-first-form-wrap-index">
                  <Button variant="dark" type="submit" width="256px">
                    Sign In
                  </Button>
                </form>

                <div className="button-form-wrap-index">
                  <a href='https://play.google.com/store/apps/details?id=space.hsat.tessa.m&pli=1' className="g-play-link">
                    <img src="./google-play-icon.png" className="gp-icon"/>
                  </a>
                </div>  
                {/* <form action="https://app.tessa.hsat.info" className="button-form-wrap-index">
                
                  <Button variant="dark" type="submit" width="256px">
                    Sign Up
                  </Button>
                </form>                 */}
              </Wrapper>
              {/* <Link to="/forgotPassword">Forgot password?</Link> */}
            </Wrapper>
          </motion.div>
        )}

        {authStatus === AuthState.SIGNED_IN && challenge?.challengeName === 'UPDATE_DETAILS' && (
          <motion.div
            animate="animate"
            exit="exit"
            initial="initial"
            key="updateDetails"
            variants={LOGIN_ANIMATION_VARIANTS}
          >
            <UpdateDetails user={userData?.me} />
          </motion.div>
        )}

        {user && challenge?.challengeName === 'NEW_PASSWORD_REQUIRED' && (
          <motion.div
            key="verifyAccount"
            variants={LOGIN_ANIMATION_VARIANTS}
            initial="initial"
            animate="animate"
            exit="exit"
          >
            <VerifyAccount onVerified={updateUser} user={user} />
          </motion.div>
        )}
        {user && challenge?.challengeName === 'MFA_SETUP' && (
          <motion.div
            key="mfaSetup"
            variants={LOGIN_ANIMATION_VARIANTS}
            initial="initial"
            animate="animate"
            exit="exit"
          >
            <SetupMFA user={user} username={values.username} />
          </motion.div>
        )}
        {user && challenge?.challengeName === 'SOFTWARE_TOKEN_MFA' && (
          <motion.div
            key="verifyMFA"
            variants={LOGIN_ANIMATION_VARIANTS}
            initial="initial"
            animate="animate"
            exit="exit"
          >
            <VerifyMFA firstSetup={false} user={user} />
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

import { ApolloProvider } from '@apollo/client';
import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga';
import { HelmetProvider } from 'react-helmet-async';
import { Redirect, Route, Router as BrowserRouter, Switch } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { PrivateRoute } from './components/PrivateRoute/PrivateRoute';
import { client } from './graphql/client';

import { ForgotPasswordPage } from './pages/login/ForgotPasswordPage/ForgotPasswordPage';
import { LoginPage } from './pages/login/LoginPage';

export const App = () => {
  const history = createBrowserHistory();

  // send GA pageview event on location change
  history.listen((location) => {
    ReactGA.pageview(location.pathname);
  });

  return (
    // <ProvideAuth>
    <HelmetProvider>
      <ToastContainer position={toast.POSITION.BOTTOM_CENTER} />
      <BrowserRouter history={history}>
        <Switch>
          <Route path="/" component={LoginPage} />
        </Switch>
      </BrowserRouter>
    </HelmetProvider>
  );
};

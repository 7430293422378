import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
* {
	box-sizing: border-box;
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	color: white;
margin: 0;
padding: 0;
border: 0;
font-size: 100%;
/* font-family: 'IBM Plex Sans', sans-serif; */
vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
display: block;
}
body {
line-height: 1;
overscroll-behavior: none;
background: ${({ theme }: any) => theme.colors.black};
}
ol, ul {
list-style: none;
}
blockquote, q {
quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
content: '';
content: none;
}
table {
border-collapse: collapse;
border-spacing: 0;
}

.gmnoprint {
	border: 1px solid rgb(77,77,77)
}
.gm-style-mtc button {
    color: white !important;
	background-color: #141414 !important;
	font-family: inherit !important;
	box-shadow: none !important;
	border-radius: 0 !important;
	font-size: 14px !important;
	height: 48px !important;
	padding: 16px !important;
}

.gm-style-mtc ul {
	background-color: #141414 !important;
	border-radius: 0 !important;
	border: 1px solid rgb(77,77,77) !important;
	margin: 4px !important;
}

.gm-style-mtc li {
	background-color: #141414 !important;
}
.gm-style-mtc li label {
	font-size: 14px;
}
.gm-style-mtc li img {
	filter: invert(1)
}

`;

import styled, { css } from 'styled-components';
import { space, SpaceProps, variant } from 'styled-system';

export const InputContainer = styled.div<SpaceProps>`
	width: 100%;
	${space}
`;

export const InputLabel = styled.label`
	${({ theme }) => css`
		color: ${theme.colors.brandFontDark};
		display: block;
		font-size: 12px;
		font-weight: 600;
		line-height: 15px;
		margin-bottom: 12px;
	`}
`;

export const StyledInput = styled.input<{ variant?: 'error' }>`
	${({ theme }) => css`
		background-color: ${theme.colors.inputPrimary};
		border: 1px solid #ffffff26;
		color: white;
		transition: border 0.3s ease;
		font-size: 16px;
		padding: 16px;
		width: 100%;
		outline: 0;
		::placeholder {
			color: ${theme.colors.brandFontDark};
		}

		${variant({
			variants: {
				error: {
					border: '1px solid #E2393C',
				},
			},
		})}
	`}
`;
